
import { PropType, computed, defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { IUser } from "@/core/data/user";
import UserService from "@/core/services/UserService";
import { IRoom } from "@/core/data/room";
import { SearchUserFilter } from "@/core/filterData/searchUserFilter";
export default defineComponent({
  name: "new-room-member-modal",
  components: {
    Datatable,
  },
  props: {
    excludedUserIds: Array<number>,
    title: String,
    description: String,
    room: Object as PropType<IRoom>,
    no_room: Boolean,
    no_group: Boolean,
  },
  emit: ["onAddBtnClick"],
  setup(props, { emit }) {
    let thisModalObj;
    const loading = ref<boolean>(false);
    const search = ref<string>("");
    const newRoomMemberModalRef = ref<null | HTMLElement>(null);
    var tableData = ref<Array<IUser>>([]);
    var totalData = ref(0);
    var currentPage = ref(1);
    var rowsPerPage = ref(10);

    const tableHeader = ref([
      {
        name: "",
        key: "profilePic",
      },
      {
        name: "Name",
        key: "fullName",
        sortable: false,
      },
      {
        name: "Email",
        key: "email",
        sortable: false,
      },
      // {
      //   name: "",
      //   key: "actions",
      // },
    ]);

    const initUserData = () => {
      var searchUserFilter: SearchUserFilter = {
        page: 1,
        text_search: search.value,
      };

      if (props.excludedUserIds!.length > 0) {
        searchUserFilter.user_exclude = props.excludedUserIds;
      }

      if (props.no_room != null && props.no_room != undefined && props.no_room) {
        searchUserFilter.no_room = true;
      }

      if (props.room != undefined && props.room != null) {
        searchUserFilter.roomId = props.room.id;
      }

      if (props.no_group != undefined && props.no_group != null && props.no_group) {
        searchUserFilter.no_group = true;
      }

      UserService.getAllUsers(searchUserFilter).then((response) => {
        totalData.value = response.data.total;
        tableData.value.splice(
          0,
          tableData.value.length,
          ...response.data.data
        );
      });
    };
    onMounted(() => {
      thisModalObj = new Modal(newRoomMemberModalRef.value);
    });

    const onSubmit = () => {
      emit("onAddBtnClick");
    };

    const assignRoomMember = (user) => {
      emit("onAddBtnClick", user);
      hideModal(newRoomMemberModalRef.value);
    };

    const searchItems = () => {
      initUserData();
    };

    const onPageChange = (pageNumber) => {
      currentPage.value = pageNumber;
      initUserData();
    };

    const showModal = () => {
      thisModalObj.show();
      initUserData();
    };
    return {
      loading,
      newRoomMemberModalRef,
      showModal,
      onSubmit,
      tableHeader,
      tableData,
      totalData,
      currentPage,
      onPageChange,
      search,
      searchItems,
      assignRoomMember,
      rowsPerPage,
    };
  },
});
